import React from 'react';
import './VideoSection.css';

const VideoSection = () => (
    <section className="video-section"      style={{
        display: 'flex',
        flexDirection: 'column', /* Stack the elements vertically */
        alignItems: 'center',
        gap: '20px'
    }}>
        <div  >
            <h2>How it Works</h2>
        </div>
        <video src="https://videogchome.s3.eu-west-2.amazonaws.com/Greencrowder.mp4" autoPlay controls playsInline />
    </section>
);

export default VideoSection;
