import React from 'react';
import './WhatWeDo.css';

const items = [
    { id: 1, icon: "tellusgoals.svg", title: "Sustainability", description: "Bring you the most impactful climate actions worldwide on topics and places that matter to you" },
    { id: 2, icon: "providevisibility.svg", title: "Energy Efficiency", description: "Promoting eco-friendly projects to the people that care about them the most to maximise visibility" },
    { id: 3, icon: "offeropportunitiestocontribute.svg", title: "Innovation", description: "Offer opportunities to crowd fund and contribute to green initiatives" },
    { id: 4, icon: "trackyourclimategoals.svg", title: "Track Goals", description: "Enable you to track your climate funding goals and impact" },
    { id: 5, icon: "exclusiveofferspartners.svg", title: "Exclusive Offers", description: "Provide exclusive offers from our partners" },
];



const WhatWeDo = () => (
    <section className="what-we-do">
        <h2>What We&apos;ll Do</h2>
        <div className="items">
            {items.map(item => (
                <div key={item.id} className="item" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <span className="icon">
                        <img src={item.icon} alt={item.title} />
                    </span>

                    <p>{item.description}</p>
                </div>
            ))}
        </div>
    </section>
);

export default WhatWeDo;
