import { Link } from 'react-router-dom';
import './HeroSection.css';

const HeroSection = ({ joinRef }) => {
    const scrollToContent = () => {
        joinRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="hero-section hero">
            <div className="content">
                <h1 className="hero-title">GreenCrowder</h1>
                <h1 className='hero-description sour-gummy-gc'>Our mission is to build the largest crowdfunding platform for climate change and environmental initiatives.</h1>
                <button className="prejoin-btn" onClick={scrollToContent}>Pre-Join Our Community</button>
                <div className="arrow-container"><div className="down-arrow" onClick={scrollToContent}></div></div>
            </div>
            <Link to="/contact" className="contact-link">Contact Us</Link>
        </div>
    );
};

export default HeroSection;
