import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [status, setStatus] = useState('idle'); // idle, sending, success, or error

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('sending'); // Show "Sending your message..."
        try {
            const response = await fetch("https://vg7sc6fpof.execute-api.eu-west-2.amazonaws.com/dev/contact", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setStatus('success'); // Show success message
            } else {
                setStatus('error'); // Show error message
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setStatus('error'); // Show error message
        }
    };

    return (
        <section className="contact-section">
            <h2>Contact Us</h2>

            {status === 'sending' && <p>Sending your message...</p>}
            {status === 'success' && <p className="success">Thank you! We'll get back to you shortly.</p>}
            {status === 'error' && <p className="error">Something went wrong. Please try again later.</p>}

            {status !== 'success' && (
                <form className="contact-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        className="contact-input"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        className="contact-input"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <textarea
                        name="message"
                        className="contact-input"
                        placeholder="Your Message"
                        rows="4"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <button type="submit" className="btn">Send Message</button>
                </form>
            )}

            <footer className="footer">
                <p>&copy; 2024 GreenCrowder. All Rights Reserved.</p>
            </footer>
            <Link to="/" className="back-home-link">Back to Home</Link>
        </section>
    );
};

export default ContactUs;
