import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import WhatWeDo from './components/WhatWeDo';
import WhatYouDo from './components/WhatYouDo';
import VideoSection from './components/VideoSection';
import ContactUs from './components/ContactUs';
import Join from './components/Joinus';
import LandingPage from './components/LandingPage'; // Ensure this is the correct path
import './App.css';

const App = () => {
    const joinRef = useRef(null);
    const navigate = useNavigate(); // Use the navigate hook

    const handleEmailSubmit = (email) => {
        console.log(`Email submitted: ${email}`); // Handle API request here if needed
        navigate('/landing'); // Redirect to LandingPage
    };

    return (
        <>
            <HeroSection joinRef={joinRef} />
            <WhatWeDo />
            <VideoSection />
            <WhatYouDo />
            <Join ref={joinRef} onSubmit={handleEmailSubmit} />
        </>
    );
};

const MainApp = () => (
    <Router>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/landing" element={<LandingPage />} /> {/* Add this route */}
        </Routes>
    </Router>
);

export default MainApp;
