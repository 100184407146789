import React from 'react';
import './WhatWeDo.css';

const items = [
    { id: 1, icon: "tellusgoals.svg", title: "Sustainability", description: "Tell us about your climate goals and prefrences" },
    { id: 2, icon: "launchclimategoal.svg", title: "Energy Efficiency", description: "Launch campaigns for any climate-related projects you have that need funding" },
    { id: 3, icon: "supportprojects.svg", title: "Innovation", description: "Support projects and causes you care about" }]



const WhatYouDo = () => (
    <section className="what-we-do">
        <h2>What You&apos;ll Do</h2>
        <div className="items">
            {items.map(item => (
                <div key={item.id} className="item" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <span className="icon">
                        <img src={item.icon} alt={item.title} />
                    </span>
                    <h3> |</h3>
                    <p> {item.description}</p>
                </div>
            ))}
        </div>
    </section>
);

export default WhatYouDo;
