import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './Landing.css';
import { Link } from 'react-router-dom';

const countryList = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
    "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain",
    "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
    "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso",
    "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
    "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia",
    "Cuba", "Cyprus", "Czech Republic", "Democratic Republic of the Congo", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland",
    "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada",
    "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
    "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North",
    "Korea, South", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho",
    "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar",
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
    "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands",
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
    "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay",
    "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia",
    "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
    "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
    "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname",
    "Sweden", "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", "Togo",
    "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States",
    "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen",
    "Zambia", "Zimbabwe"
];

const LandingPage = () => {
    const [isEmailSubmitted] = useState(true);
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [surveyAnswers, setSurveyAnswers] = useState({
        gofundme: '',
        careAboutSustainability: '',
        supportFriend: '',
        supportCommunity: '',
        country: ''
    });
    const [countryInput, setCountryInput] = useState('');
    const [suggestedCountries, setSuggestedCountries] = useState([]);
    const [email, setEmail] = useState(null);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromQuery = queryParams.get('email');
        if (emailFromQuery) {
            setEmail(emailFromQuery);
        }

        document.body.classList.add('landing-page-body');
        return () => {
            document.body.classList.remove('landing-page-body');
        };
    }, [location]);


    const handleSurveyChange = (e) => {
        const { name, value } = e.target;
        setSurveyAnswers({ ...surveyAnswers, [name]: value });
    };

    const handleCountryInput = (e) => {
        const input = e.target.value;
        setCountryInput(input);
        if (input) {
            const filteredCountries = countryList.filter(country =>
                country.toLowerCase().includes(input.toLowerCase())
            );
            setSuggestedCountries(filteredCountries);
        } else {
            setSuggestedCountries([]);
        }
    };

    const handleCountrySelect = (country) => {
        setSurveyAnswers({ ...surveyAnswers, country });
        setCountryInput(country);
        setSuggestedCountries([]);
    };

    const handleSurveySubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        fetch('https://vg7sc6fpof.execute-api.eu-west-2.amazonaws.com/dev/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, ...surveyAnswers }),
        })
        .then(response => {
            setIsLoading(false);
            if (response.ok) {
                setIsSurveySubmitted(true);
            } else {
                console.error('Failed to submit survey. Response:', response);
            }
        })
        .catch(error => {
            setIsLoading(false);
            console.error('Error:', error);
        });
    };

    return (
        <div className="landing-page page-reset">
            <div className="landing-header">
                <h1>GreenCrowder</h1>
                <p>Our mission is to build the largest climate crowdfunding platform that allows us help each other be more sustainable through giving </p>
            </div>

            {isEmailSubmitted && !isSurveySubmitted && (
                <section className="survey-section">
                    <h2>And a Quick Survey, Please</h2>
                    <p>Please take a moment to answer a few questions to help us understand your preferences better.</p>
                    <form className="survey-form" onSubmit={handleSurveySubmit}>
                        {/* Country question */}
                        <div className="form-group">
                            <label>What country do you live in?</label>
                            <input
                                type="text"
                                value={countryInput}
                                onChange={handleCountryInput}
                                placeholder="Start typing your country..."
                                className="country-input"
                            />
                            {suggestedCountries.length > 0 && (
                                <ul className="country-suggestions">
                                    {suggestedCountries.map((country) => (
                                        <li key={country} onClick={() => handleCountrySelect(country)}>
                                            {country}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* Question 1 */}
                        <div className="form-group">
                            <label>Have you ever used crowdfunding platform?</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="gofundme"
                                        value="yes"
                                        checked={surveyAnswers.gofundme === 'yes'}
                                        onChange={handleSurveyChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="gofundme"
                                        value="no"
                                        checked={surveyAnswers.gofundme === 'no'}
                                        onChange={handleSurveyChange}
                                    /> No
                                </label>
                            </div>
                        </div>

                        {/* Question 2 */}
                        <div className="form-group">
                            <label>Do you care about sustainability?</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="careAboutSustainability"
                                        value="yes"
                                        checked={surveyAnswers.careAboutSustainability === 'yes'}
                                        onChange={handleSurveyChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="careAboutSustainability"
                                        value="no"
                                        checked={surveyAnswers.careAboutSustainability === 'no'}
                                        onChange={handleSurveyChange}
                                    /> No
                                </label>
                            </div>
                        </div>

                        {/* Question 3 */}
                        <div className="form-group">
                            <label>Would you support a friend in a crowdfunding campaign to achieve an eco-friendly project (like buying an electric car)?</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="supportFriend"
                                        value="yes"
                                        checked={surveyAnswers.supportFriend === 'yes'}
                                        onChange={handleSurveyChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="supportFriend"
                                        value="no"
                                        checked={surveyAnswers.supportFriend === 'no'}
                                        onChange={handleSurveyChange}
                                    /> No
                                </label>
                            </div>
                        </div>

                        {/* Question 4 */}
                        <div className="form-group">
                            <label>Would you support a community project to achieve an eco-friendly project ?</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="supportCommunity"
                                        value="yes"
                                        checked={surveyAnswers.supportCommunity === 'yes'}
                                        onChange={handleSurveyChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="supportCommunity"
                                        value="no"
                                        checked={surveyAnswers.supportCommunity === 'no'}
                                        onChange={handleSurveyChange}
                                    /> No
                                </label>
                            </div>
                        </div>

                        <button type="submit" className="btn"  disabled={isLoading}>
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                     <Link to="/"
                            style={{
                                display: "block",
                                textAlign: "center",
                                margin: "20px auto",
                                color: "#007BFF",
                                textDecoration: "none",
                                fontSize: "16px"
                            }}
                        >
    Back to Home
</Link>

                </section>
            )}

            {/* Thank You Section */}
            {isSurveySubmitted && (
                <section className="thank-you-section">
                    <h2>Thank you for submitting the survey!</h2>
                    <p>Your insights are valuable to us. We’ll keep you updated about our progress.</p>
                     <Link to="/" className="back-home-link">Back to Home</Link>
                </section>
            )}
        </div>
    );
};

export default LandingPage;
