import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './WhatWeDo.css';
import './Join.css';

const Join = React.forwardRef(({ onSubmit }, ref) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate for redirection

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading state
        setError(null); // Reset any previous errors

        try {
            // Send email to the API
            const response = await fetch('https://vg7sc6fpof.execute-api.eu-west-2.amazonaws.com/dev/joinus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit email');
            }

            // Once email is submitted successfully, pass it to parent component
            const responseData = await response.json();
            onSubmit(responseData.email); // Call the onSubmit function passed from parent

            // Clear the email input after successful submission
            setEmail('');

            // Redirect to the landing page after success
            // Replace '/landing' with your actual route for the landing page
            navigate(`/landing?email=${encodeURIComponent(email)}`);

        } catch (err) {
            setError('There was an issue submitting your email. Please try again later.');
        } finally {
            setLoading(false); // Stop loading state
        }
    };

    return (
        <section className="what-we-do" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }} ref={ref}>
            <h2>Join Us</h2>
            <p>We are still building the GreenCrowder platform. Join our community to stay updated with our progress and be the first to know when we launch!</p>
            <form className="email-form" onSubmit={handleSubmit}>
                <input
                    type="email"
                    className="email-input"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit" className="btn" disabled={loading}>
                    {loading ? 'Submitting...' : 'Subscribe'}
                </button>
            </form>
            {error && <p className="error">{error}</p>}
            <footer className="footer">
                <p>&copy; 2024 GreenCrowder. All Rights Reserved.</p>
            </footer>
        </section>
    );
});

export default Join;
